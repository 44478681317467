<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside" style="box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
            ">
    <!--begin::Primary-->
    <div class="aside-primary d-flex flex-column align-items-center flex-row-auto" role="tablist">
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
        style="height: 500px; width: 100px; overflow: hidden;">
        <!-- width: 400px; -->

        <!--begin::Nav-->
        <ul class="nav flex-column">
          <!--begin::Item-->

          <li class="nav-item mb-3" data-placement="right" data-container="body" data-boundary="window"
            v-b-tooltip.hover.right="'Show my files'">
            <a href="#/dashboard" class="nav-link btn btn-icon btn-clean btn-lg" @click.native="closeOffcanvas"
              data-toggle="tab" v-on:click="setActiveTab" data-tab="0" ref="Btn">
              <span class="svg-icon svg-icon-xl" style="font-size: 12px;">
                <inline-svg src="media/svg/icons/General/myfiles.svg" />
                My&nbsp;Files
                <!--end::Svg Icon-->
              </span> </a><br />&nbsp;
          </li>

          <li class="nav-item1 mb-3" data-placement="right" data-container="body" data-boundary="window"
            v-b-tooltip.hover.right="
                          'Our system allows you to map your data once uploaded, however if you\'d prefer to do this before, you can download a template here'
                        ">
            <a href="/media/template/RMD_Template.csv" class="btn btn-icon btn-clean btn-lg" data-toggle="tab">
              <span class="svg-icon svg-icon-xl" style="font-size: 12px;">
                <inline-svg src="media/svg/icons/General/template_icon.svg" />
                Template
                <!--end::Svg Icon-->
              </span> </a><br />&nbsp;
          </li>

          <li class="nav-item1 mb-3" data-placement="right" data-container="body" data-boundary="window"
            v-b-tooltip.hover.right="'Enable/Disable Tutorial'">
            <a href="#" id="startHelpRef" @click="StopHelp()" style="display:none"></a>
            <a @click="StartHelp()" v-on:startHelp="StartHelp()" class="btn btn-icon btn-clean btn-lg" data-toggle="tab">
              <span class="svg-icon svg-icon-xl" style="font-size: 12px;">
                <inline-svg src="media/svg/icons/General/help_icon.svg" />
                Help&nbsp;{{ helpActiveValue ? 'On' : 'Off' }}
                <!--end::Svg Icon-->
              </span> </a><br />&nbsp;
          </li>

          <li v-if="this.$store.getters.currentUser.userData.admin==1" class="nav-item1 mb-3" data-placement="right" data-container="body" data-boundary="window"
            v-b-tooltip.hover.right="'Settings'">
            <a href="#/dashboard/settings/edit" class="nav-link btn btn-icon btn-clean btn-lg"
              @click.native="closeOffcanvas" data-toggle="tab" v-on:click="setActiveTab" data-tab="4">
              <span class="svg-icon svg-icon-xl" style="font-size: 12px;">
                <inline-svg src="media/svg/icons/General/settings_icon.svg" />
                Setting
              </span> </a>
          </li>


        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->


      <!--begin::Footer-->
      <div class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10 ">
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->
        <!-- <KTQuickPanel :refreshValue="refreshValue"></KTQuickPanel> -->

        <span class="label label-sm label-light-danger label-rounded font-weight-bolder"
          style="margin-bottom: -26px;margin-left: 39px;">
          {{notifications.length}}
        </span>
        <div class="btn btn-icon btn-clean btn-lg w-40px h-60px position-relative "
          v-b-tooltip.hover.right="'Notifications'">
          <!--begin::Item-->
          <div v-if="notifications.length>0">
            <a href="#/dashboard/notification/show" class="nav-link btn btn-icon btn-clean btn-lg"
              @click.native="closeOffcanvas" data-toggle="tab" v-on:click="setActiveTab" data-tab="2">
              <span class="svg-icon svg-icon-xl" style="font-size: 12px;">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/General/notification_icon.svg" />
                <!--end::Svg Icon-->
                Notification
              </span>
            </a>
          </div>
          <div v-else>
            <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" @click.native="closeOffcanvas" data-toggle="tab"
              v-on:click="setActiveTab" data-tab="3">
              <span class="svg-icon svg-icon-xl" style="font-size: 12px;">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/General/notification_icon.svg" />
                <!--end::Svg Icon-->
                Notification
              </span>
            </a>
          </div>
        </div>

        <!--end::Quick Panel-->
        <!--begin::User-->

        <!-- <KTQuickUser></KTQuickUser>     -->
        &nbsp;
        <div class="btn btn-icon btn-clean btn-lg" v-b-tooltip.hover.right="'Profile'">
          <!--begin::Item-->
          <a href="#/profile/edit" class="nav-link btn btn-icon btn-clean btn-lg" @click.native="closeOffcanvas"
            data-toggle="tab" v-on:click="setActiveTab" data-tab="2">
            <span class="svg-icon svg-icon-xl">
              <inline-svg src="media/svg/icons/General/profile_icon.svg" />
              Profile
            </span>


          </a>
        </div>

        <br />&nbsp;
        <div class="btn btn-icon btn-clean btn-lg" v-b-tooltip.hover.right="'Sign-Out'">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" v-on:click="onLogout" data-tab="1">
            <span class="svg-icon svg-icon-xl" style="font-size: 12px;">
              <inline-svg src="media/svg/icons/General/exit_icon.svg" />
              Logout
            </span>
          </a>
        </div>

        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs>div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
//import KTMenu from "@/view/layout/aside/Menu.vue";
// import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import { UPDATE_USER_SETTING } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "KTAside",
  props: {
    method: { type: Function },
    "refreshValue": String,
    "propName": String,
  },
  watch: {
    refreshValue: function () {
      this.getNotification();
    },
    propName() {
      this.getNotification();
    }
  },
  data() {
    return {
      buttonClicked: false,
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0,
      helpActive: true,
      userSettings: null,
      notifications: [],
    };
  },
  components: {
    KTBrand,
    //KTMenu,
    // KTQuickUser,
    // KTQuickPanel
  },
  mounted() {
    this.$refs.Btn.click();

    this.getNotification();

    setTimeout(() => {
      const asideRef = this.$refs["kt_aside"];
      if (this.$store.getters.currentUser.userData.onboarding_flag == '0' || this.$store.getters.currentUser.userData.onboarding_flag == false) {
        this.helpActive = false;
      } else {
        this.helpActive = true;
      }

      // Init Aside
      KTLayoutAside.init(asideRef);

      asideRef.querySelectorAll("a[class='menu-link']").forEach(item => {
        item.addEventListener("click", () => {
          KTLayoutAside.getOffcanvas().hide();
        });
      });

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    }, 1000);
  },
  methods: {
    
    toggleButtonClick() {
      this.buttonClicked = !this.buttonClicked;
    },
    StartHelp() {

      if (this.$store.getters.currentUser.userData.onboarding_flag == '0' || this.$store.getters.currentUser.userData.onboarding_flag == false) {
        this.helpActive = false;
      } else {
        this.helpActive = true;
      }

      this.helpActive = !this.helpActive;
      var onboarding = this.$store.getters.currentUser.userData.onboarding;
      var heatmap = this.$store.getters.currentUser.userData.heatmap;
      var onboarding_flag = this.helpActive;
      heatmap = "Y";
      this.$store.dispatch(
        UPDATE_USER_SETTING,
        { onboarding, heatmap, onboarding_flag },
        this.$store.getters.currentUser.userData.id
      );

      var value = new Date();
      if (this.helpActive) {
        setTimeout(() => {
          this.$emit("HelpButtonValue", value);
        }, 2000)
      }

    },
    StopHelp() {

      this.helpActive = false;

      var onboarding = this.$store.getters.currentUser.userData.onboarding;
      var heatmap = this.$store.getters.currentUser.userData.heatmap;
      var onboarding_flag = false;

      this.$store.dispatch(
        UPDATE_USER_SETTING,
        { onboarding, heatmap, onboarding_flag },
        this.$store.getters.currentUser.userData.id
      );

      var value = new Date();
      if (this.helpActive) {
        setTimeout(() => {
          this.$emit("HelpButtonValue", value);
        }, 2000)
      }

    },
    getNotification() {
      console.warn('get notification called');
      // ApiService.setHeader();	
      ApiService.get("apps/notifications/all")
        .then(({ data }) => {
          // Swal.close();	
          this.notifications = data.notifications;
        })
        .catch(() => {
          // Swal.close();	
        })
    },
    StartRefresh() {
      // var value = new Date();
      this.method();
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses","currentUser", "isAuthenticated"]),

    /**
     * Get extra classes for menu based on the options
     */
    helpActiveValue() {
      if (this.$store.getters.currentUser.userData) {
        if (this.$store.getters.currentUser.userData.onboarding_flag == '0' || this.$store.getters.currentUser.userData.onboarding_flag == false) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }

    },
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
