<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled" @HelpButtonValue="updateHelpValue" @RefreshButtonValue="updateRefreshValue"
        :key="componentKey" :method="forceRerender" :propName="passedPropName"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Content -->
        <div id="kt_content" class="content d-flex flex-column flex-column-fluid" :class="pageClass()"
          style="background:#F8F8F9;">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader v-if="subheaderDisplay" v-bind:breadcrumbs="breadcrumbs" v-bind:title="pageTitle" />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div :class="{
                    'container-fluid': contentFluid,
                    container: !contentFluid
                  }">
              <transition name="fade-in-up">
                <router-view :helpValue="helpValue" :refreshValue="refreshValue" @notif-refresh="notifRefresh" />
              </transition>
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
    <KTStickyToolbar :showToolbar="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import axios from "axios";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    Loader
  },
  data() {
    return {
      helpValue: new Date(),
      refreshValue: new Date(),
      passedPropName: new Date(),
    }
  },
  created(){
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          console.log('Session timed out');
          // Redirect the user to the login page
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    console.warn('authenticate check');
    console.warn(this.$store.getters.isAuthenticated);
    // check if current user is authenticated
    if ( !this.$store.getters.isAuthenticated ) {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));

      // this.$router.push({ name: "login" });
    } else {
      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 2000);
    }

    //check net::ERR_CONNECTION_TIMED_OUT
    window.onerror = (message) => {
      console.warn('window error '.message);
      if (message === 'net::ERR_CONNECTION_TIMED_OUT') {
        this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
      }
    };

  },
  methods: {
    notifRefresh(value) {
      this.passedPropName = value;
      // this.refreshValue = value;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    updateHelpValue(val) {
      this.helpValue = val;
    },
    updateRefreshValue(val) {
      this.refreshValue = val;
    },
    pageClass() {
      return this.$route.name;
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "rightAsideConfig"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },


    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      const rightSide = this.rightAsideConfig;
      return rightSide.display;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

.page {
  font-family: 'Poppins', sans-serif;
}
</style>